<template>
  <div>
    <CaHeader
      heading="Kunden"
      :column-layout="true"
    >
      <template #buttonSpace>
        <router-link
          v-if="$can('addOwnCustomers')"
          class="btn btn-primary"
          :to="{name: 'CustomerCreate' }"
        >
          Neuen Kunden anlegen
        </router-link>
      </template>
    </CaHeader>
    <div class="container-fluid px-4 pt-4">
      <DataTable
        :fields="fields"
        service="customer"
        :base-query="baseQuery"
        :search-field="false"
        @row-clicked="(item) => $router.push({name: 'CustomerDetail', params: {customerId: item._id}})"
      >
        <template #customSearch>
          <div class="row align-items-center">
            <div class="col-auto font-weight-bold">
              Suche:
            </div>
            <BasicInput
              v-model="searchLastname"
              placeholder="Nachname"
              :margin="false"
              type="search"
              class="col"
            />
            <BasicInput
              v-model="searchFirstname"
              placeholder="Vorname"
              :margin="false"
              type="search"
              class="col"
            />
            <!-- <BasicInput
              v-model="searchCompany"
              placeholder="Firma"
              :margin="false"
              type="search"
              class="col"
            /> -->
            <BasicInput
              v-model="searchPlz"
              placeholder="PLZ"
              :margin="false"
              type="search"
              class="col"
            />
            <BasicInput
              v-model="searchCity"
              placeholder="Ort"
              :margin="false"
              type="search"
              class="col"
            />
            <BasicInput
              v-model="searchUsername"
              placeholder="Benutzername"
              :margin="false"
              type="search"
              class="col"
            />
          </div>
        </template>
        <template
          slot="name"
          slot-scope="{item: data}"
        >
          <div v-if="data.name.ANR === 'f' || data.kind === 'FIRMA'">
            <div class="d-inline my-auto">
              <p class="d-inline-flex my-auto">
                {{ data.name.NAM }}&nbsp;
              </p>
              <p
                v-if="data.address.ZUSATZ1"
                class="d-inline-flex my-auto"
              >
                {{ data.address.ZUSATZ1 }}&nbsp;
              </p>
              <p
                v-if="data.address.ZUSATZ2"
                class="d-inline-flex my-auto"
              >
                {{ data.address.ZUSATZ2 }}&nbsp;
              </p>
              <p
                v-if="data.address.ZUSATZ3"
                class="d-inline-flex my-auto"
              >
                {{ data.address.ZUSATZ3 }}&nbsp;
              </p>
            </div>
          </div>
          <div v-else>
            <p class="my-auto">
              {{ data.name.NAM }}&nbsp;
            </p>
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header.vue'
import DataTable from '@/components/DataTable/DataTable.vue'
import formatDate from '@/filters/formatDate'
import BasicInput from '@/components/BaseComponents/BasicInput'

export default {
  components: {
    CaHeader,
    DataTable,
    BasicInput
  },
  data () {
    return {
      searchLastname: '',
      searchFirstname: '',
      searchCompany: '',
      searchPlz: '',
      searchCity: '',
      searchUsername: ''
    }
  },
  computed: {
    fields () {
      return [
        {
          label: 'Nachname',
          key: 'name'
        },
        {
          label: 'Vorname',
          key: 'name.VOR'
        },
        {
          label: 'Anschrift',
          key: 'address.STR'
        },
        {
          label: 'Anschrift',
          key: 'address.PLZ'
        },
        {
          label: 'Anschrift',
          key: 'address.ORT'
        },
        {
          label: 'Geburtsdatum',
          key: 'misc',
          formatter: (misc) => `${formatDate(misc.GEBDAT)}`
        },
        {
          label: 'E-Mail',
          key: 'contact.MAIL'
        },
        {
          label: 'Benutzername',
          key: 'account.username'
        }
      ]
    },
    baseQuery () {
      const query = {
        $client: { $lookup: {} },
        'name.NAM': { $exists: true }
      }
      if (this.searchLastname) {
        const seachTerms = this.searchLastname.split(' ')
        const searchFields = ['name.NAM', 'name.NAM2', 'name.VOR', 'name.VOR2', 'address.ZUSATZ1', 'address.ZUSATZ2', 'address.ZUSATZ3']

        if (!query.$and) {
          query.$and = []
        }

        const orQuery = seachTerms.map(term => {
          return {
            $or: searchFields.map(field => {
              return { [field]: { $regex: term, $options: 'i' } }
            })
          }
        })

        orQuery.map(elem => {
          query.$and.push(elem)
        })
      }
      if (this.searchFirstname) {
        query['name.VOR'] = { $regex: this.searchFirstname, $options: 'i' }
      }
      // Kommt mit Umbau des Kunden
      // if (this.searchCompany) {
      //   query['name.VOR'] = { $regex: this.searchCompany, $options: 'i' }
      // }
      if (this.searchPlz) {
        query['address.PLZ'] = { $regex: this.searchPlz, $options: 'i' }
      }
      if (this.searchCity) {
        query['address.ORT'] = { $regex: this.searchCity, $options: 'i' }
      }
      if (this.searchUsername) {
        query.$client.$lookup.accountUsername = this.searchUsername
      }
      return query
    }
  }
}
</script>
